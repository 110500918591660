import { http } from "../../../api/http-common";

const getAllPlanType = async (params) => {
  try {
    const { data } = await http.get("plan-types", { params });
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getAllModificationApprovers = async (params) => {
  try {
    const { data } = await http.get("plan-types/modification-approvers", { params });
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getPlanTypeById = async (id) => {
  try {
    const { data } = await http.get(`plan-types/${id}`);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const savePlantype = async (payload) => {
  try {
    const { data } = await http.post("plan-types", payload);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveModificationApprovers = async (payload) => {
  try {
    const { data } = await http.post("plan-types/modification-approvers", payload);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateModificationApprovers = async (id, payload) => {
  try {
    const { data } = await http.put("plan-types/modification-approvers/" + id, payload);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updatePlantype = async (id, payload) => {
  try {
    const { data } = await http.put("plan-types/" + id, payload);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getAllPlanType,
  getPlanTypeById,
  savePlantype,
  updatePlantype,
  saveModificationApprovers,
  getAllModificationApprovers,
  updateModificationApprovers
};
