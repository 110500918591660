import * as planTypeService from "./service/plan-type.service";

const state = {
  planType: null,
  planTypes: [],
  planTypesPagination: {
    page: 1,
    limit: 10,
    totalItems: 0,
    totalPages: 0,
  },
  allPlanTypes: [],
  loadingPlanType: false,
};

const mutations = {
  SET_LOADING_PLAN_TYPE(state, data) {
    state.loadingPlanType = data;
  },

  SET_PLAN_TYPE(state, planType) {
    state.planType = planType;
  },

  SET_ALL_PLAN_TYPES(state, data) {
    state.allPlanTypes = data;
  },

  SET_PLAN_TYPES(state, payload) {
    state.planTypes = payload;
  },

  SET_PLAN_TYPES_PAGINATION(state, payload) {
    state.planTypesPagination = {
      ...state.planTypesPagination,
      ...payload,
    };
  },

  SET_PLAN_TYPES_UPDATE(state, payload) {
    state.planType = payload;
  },
};

const actions = {
  async createPlanType(__, data) {
    try {
      const response = await planTypeService.savePlantype(data);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async createModificationApprovers(__, data) {
    try {
      const response = await planTypeService.saveModificationApprovers(data);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listAllModificationApprovers(__, data) {
    try {
      const response = await planTypeService.getAllModificationApprovers(data);
      // commit("SET_ALL_PLAN_TYPES", response.data);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async getPlantypeById({ commit }, id) {
    try {
      const response = await planTypeService.getPlanTypeById(id);
      commit("SET_PLAN_TYPE", response);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listAllPlanTypes({ commit }, data) {
    try {
      const response = await planTypeService.getAllPlanType(data);
      commit("SET_ALL_PLAN_TYPES", response.data);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listActionPlanPagination({ commit }, data) {
    try {
      commit("SET_PLAN_TYPES", []);
      const response = await planTypeService.getAllPlanType(data);
      const pagination = {
        page: response?.pagination.page,
        limit: response?.pagination.limit,
        totalItems: response?.pagination.totalItems,
        totalPages: response?.pagination.totalPages,
      };
      commit("SET_PLAN_TYPES", response?.data || []);
      commit("SET_PLAN_TYPES_PAGINATION", pagination || {});
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async editPlanType(__, databody) {
    try {
      const { id, payload } = databody;
      const response = await planTypeService.updatePlantype(id, payload);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async editModificationApprover(__, databody) {
    try {
      const { id, payload } = databody;
      const response = await planTypeService.updateModificationApprovers(id, payload);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  cleanPlanTypeId({ commit }) {
    commit('SET_PLAN_TYPE', null);
  },

  cleanPlanType({ commit }) {
    commit("SET_PLAN_TYPES", []);
    commit("SET_PLAN_TYPES_PAGINATION", {
      page: 0,
      limit: 0,
      totalItems: 0,
      totalPages: 0,
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
