import * as evidenceActionPlan from "./service/evidence-action-plan.service";

const state = {
	evidenceActionPlan: null,
	evidencesActionPlan: [],
	evidencesActionPlanPagination: {
		page: 1,
		limit: 10,
		totalItems: 0,
		totalPages: 0,
	},
	allEvidenceActionPlans: [],
	loadingEvidenceActionPlan: false,
	totals: [],
	//
	exportReportActionPlan : [],
	allexportReportActionPlan : [],
	exportReportActionPlanPagination: {
		page: 1,
		limit: 10,
		totalItems: 0,
		totalPages: 0,
	},
	//
};

const mutations = {
	SET_LOADING_EVIDENCE_ACTION_PLAN(state, data) {
		state.loadingEvidenceActionPlan = data;
	},

	SET_EVIDENCE_ACTION_PLAN(state, evidenceActionPlan) {
		state.evidenceActionPlan = evidenceActionPlan;
	},

	SET_ALL_EVIDENCES_ACTION_PLAN(state, data) {
		state.allEvidenceActionPlans = data;
	},

	SET_EVIDENCES_ACTION_PLAN(state, payload) {
		state.evidencesActionPlan = payload;
	},

	SET_EVIDENCES_ACTION_PLAN_PAGINATION(state, payload) {
		state.evidencesActionPlanPagination = { ...state.evidencesActionPlanPagination, ...payload };
	},

	//export report 
	SET_ALL_EXPORT_REPORT_ACTION_PLAN(state, data) {
		state.allexportReportActionPlan = data;
	},

	SET_EXPORT_REPORT_ACTION_PLAN(state, payload) {
		state.exportReportActionPlan = payload;
	},

	SET_EXPORT_REPORT_ACTION_PLAN_PAGINATION(state, payload) {
		state.exportReportActionPlanPagination = { ...state.exportReportActionPlanPagination, ...payload };
	},
	//
}

const actions = {
	async uploadEvidenceActionPlan(__, data) {
		try {
			const response = await evidenceActionPlan.saveUploadEvidenceActionPlan(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async confirmObservedEvidenceActionPlan(__, data) {
		try {
			const response = await evidenceActionPlan.confirmObservedEvidence(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getEvidenceActionPlanById({ commit }, id) {
		try {
			const response = await evidenceActionPlan.getEvidenceActionPlanById(id);
			commit('SET_EVIDENCE_ACTION_PLAN', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getHEadersExportReport(__, id) {
		try {
			const response = await evidenceActionPlan.getHeadersExportReportActionPlanId(id);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listAllEvidenceActionPlan({ commit }, data) {
		try {
			const response = await evidenceActionPlan.getAllEvidencesActionPlan(data);
			commit('SET_ALL_EVIDENCES_ACTION_PLAN', response.data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listAllExportReportActionPlan({ commit }, data) {
		try {
			const response = await evidenceActionPlan.getAllExportReportActionPlan(data);
			commit('SET_ALL_EXPORT_REPORT_ACTION_PLAN', response.data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listExportReportActionPlanPagination({ commit }, data) {
		try {
			commit('SET_EXPORT_REPORT_ACTION_PLAN', []);
			const response = await evidenceActionPlan.getAllExportReportActionPlan(data);
			const pagination = {
				page: response?.pagination.page,
				limit: response?.pagination.limit,
				totalItems: response?.pagination.totalItems,
				totalPages: response?.pagination.totalPages,
			};
			commit('SET_EXPORT_REPORT_ACTION_PLAN', response?.data || []);
			commit('SET_EXPORT_REPORT_ACTION_PLAN_PAGINATION', pagination || {});
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async registerExportReportActionPlan(__, data) {
		try {
			const response = await evidenceActionPlan.saveExportReportActionPlan(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getExportReportId(__, params) {
		try {
			const response = await evidenceActionPlan.getExportReportActionPlanId(params.id);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listActionPlanPagination({ commit }, data) {
		try {
			commit('SET_EVIDENCES_ACTION_PLAN', []);
			const response = await evidenceActionPlan.getAllEvidencesActionPlan(data);
			const pagination = {
				page: response?.pagination.page,
				limit: response?.pagination.limit,
				totalItems: response?.pagination.totalItems,
				totalPages: response?.pagination.totalPages,
			};
			commit('SET_EVIDENCES_ACTION_PLAN', response?.data || []);
			commit('SET_EVIDENCES_ACTION_PLAN_PAGINATION', pagination || {});
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async reverseEvidence({ commit }, id) {
		try {
			//   const { id, payload } = databody;
			const response = await evidenceActionPlan.reverseConfirmedEvidence(id);
			commit('SET_EVIDENCE_ACTION_PLAN', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || "Error en el servicio" };
		}
	},

	async deleteEvidenceActionPlan(__, id) {
		try {
			//   const { id, payload } = databody;
			const response = await evidenceActionPlan.deleteEvidenceActionPlan(id);
			// commit('SET_EVIDENCE_ACTION_PLAN', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || "Error en el servicio" };
		}
	},

	async deleteEvidence(__, id) {
		try {
			const response = await evidenceActionPlan.deleteEvidence(id);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || "Error en el servicio" };
		}
	},

	// cleanActionPlan({ commit }) {
	// 	commit('SET_ACTION_PLANS', []);
	// 	commit('SET_ACTION_PLANS_PAGINATION', {
	// 		page: 0,
	// 		limit: 0,
	// 		totalItems: 0,
	// 		totalPages: 0,
	// 	});
	// },

	cleanEvidencesActionPlan({commit}){
		commit('SET_EVIDENCES_ACTION_PLAN',[])
		commit('SET_EVIDENCES_ACTION_PLAN_PAGINATION',[])
	},

	cleanEvidenceActionPlanId({ commit }) {
		commit('SET_EVIDENCE_ACTION_PLAN', null);
	},

}

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
