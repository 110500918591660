import { http } from '../../../api/http-common';

const getAllEvidencesActionPlan = async (params) => {
	try {
		const { data } = await http.get("evidences-plan-action", { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getAllExportReportActionPlan = async (params) => {
	try {
		const { data } = await http.get("evidences-plan-action/export-report", { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getExportReportActionPlanId = async (id) => {
	try {
		const response = await http.get(`evidences-plan-action/export-report/${id}`, {
			id,
			responseType: 'blob'
		});

		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `Reporte_Accion_Plan.xlsx`);
		document.body.appendChild(link);
		link.click();
	} catch (error) {
		return Promise.reject(error);
	}
};

const getEvidenceActionPlanById = async (id) => {
	try {
		const { data } = await http.get(`evidences-plan-action/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getHeadersExportReportActionPlanId = async (id) => {
	try {
		const { data } = await http.get(`evidences-plan-action/headers/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveUploadEvidenceActionPlan = async (payload) => {
	try {
		const { data } = await http.post('evidences-plan-action/upload-evidence', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveExportReportActionPlan = async (payload) => {
	try {
		const response = await http.post('evidences-plan-action/export-report', payload, {
			responseType: 'blob'
		});

		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'Reporte.xlsx');
		document.body.appendChild(link);
		link.click();
	} catch (error) {
		return Promise.reject(error);
	}
};

const confirmObservedEvidence = async (payload) => {
	try {
		const { data } = await http.post('evidences-plan-action/confirm-evidence', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const reverseConfirmedEvidence = async (id) => {
	try {
		const { data } = await http.put("evidences-plan-action/reverse-confirmed-evidence/" + id);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteEvidenceActionPlan = async (id) => {
	try {
		const { data } = await http.delete("evidences-plan-action/" + id);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteEvidence = async (id) => {
	try {
		const { data } = await http.delete("evidences-plan-action/delete-evidence/" + id);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};


export {
	getAllEvidencesActionPlan,
	getEvidenceActionPlanById,
	saveUploadEvidenceActionPlan,
	confirmObservedEvidence,
	reverseConfirmedEvidence,
	deleteEvidenceActionPlan,
	deleteEvidence,
	getAllExportReportActionPlan,
	getHeadersExportReportActionPlanId,
	saveExportReportActionPlan,
	getExportReportActionPlanId
};
